import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAAcZ50pchqnr8PEBSEQBCcql0sIkniuNk",
    authDomain: "huuproject-1dde9.firebaseapp.com",
    projectId: "huuproject-1dde9",
    storageBucket: "huuproject-1dde9.appspot.com",
    messagingSenderId: "56551521170",
    appId: "1:56551521170:web:19b635233fbfad43c45f4b",
    measurementId: "G-R87VYEP2XM"
};

// Initialize Firebase and analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics