import React from 'react';
import { Button } from 'semantic-ui-react';
import JusticeForWomen from '../assets/images/JusticeforWomen.png';
import KumaLogo from '../assets/images/KumaLogo.jpg';
import ImpactLogo from '../assets/images/IMPACTLogo.png';
import MedicaLogo from '../assets/images/MedicaLogo.jpg';
import SolaceMinistries from '../assets/images/SolaceMinistries.png';
import NadaLogo from '../assets/images/NADALogo.png';
import { isMobile, browserName } from "react-device-detect";

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">

      <div>
        <h1>{client.description}</h1>

        {isMobile ? "Project cannot be run on mobile!" : 
          <Button id="button-86" onClick={() => props.Launch()}>Enter the Experience</Button>           
        }

        <h4 style={{marginTop: '50px', marginBottom: '-40px'}}>Partners of this project: </h4>

        <div id="logoContainer"> 
          <img src={JusticeForWomen} alt='' style={{height: '100px', padding: '15px'}}/>
          <img src={KumaLogo} alt='' style={{height: '100px', padding: '15px'}}/>
          <img src={ImpactLogo} alt='' style={{height: '100px', padding: '15px'}}/>
          <img src={MedicaLogo} alt='' style={{height: '100px', padding: '15px'}}/>
          <img src={SolaceMinistries} alt='' style={{height: '100px', padding: '15px'}}/>
          <img src={NadaLogo} alt='' style={{height: '100px', padding: '15px'}}/>
        </div>
      </div>
      {/* <img alt="PureWeb Logo" src="/pureweb.svg" style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }} /> */}
    </div>
  );
};
