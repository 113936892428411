import React, { useContext, useState } from "react";
import { WindowWidthContext } from "../Landing";
import Title from "./components/Title/Title";
import style from "./MainPage_Style";
import Countdown from "./components/Countdown/Countdown";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FooterText } from "../constants/text";


const MainPage = () => {
  const windowWidth = useContext(WindowWidthContext);
  const [timer, setTimer] = useState();
  console.log("this is window width", windowWidth);
  let isDesktop = windowWidth > 768 ? true : false;

  return (
    <div style={style.parentDiv}>
      <div
        style={
          isDesktop ? style.pictureBackground : style.mobilePictureBackground
        }
      >
        <div style={isDesktop ? style.countdownDiv : style.countdownDivMobile}>
          <Countdown setTimer={setTimer} />
        </div>
       
      </div>

      <div style={isDesktop ? style.textDiv : style.mobileTextDiv}>
        <Title />
      </div>
      <div style={style.socialMediaDiv}>
        <a href=" https://www.facebook.com/huu.world/" target="_blank">
          <FaFacebook style={style.iconSize} color="white" />
        </a>
        <a href=" https://www.instagram.com/huu.world/" target="_blank">
          <FaInstagram style={style.iconSize} color="white" />
        </a>
      </div>
      <div style={style.footerDiv}>
        <hr style={style.hrStyle}></hr>
          <FooterText/>
      </div>
    </div>
  );
};

export default MainPage;
