const style = {
  parentDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    maxHeight: "32vh",
    maxWidth: "max-content",
    marginTop: "2%",
  },
  parentDivMobile: {
    display: "flex",
    flexDirection: "column" as "column",
    maxHeight: "32vh",
    maxWidth: "max-content",
    marginLeft: "5.555555555555555%"
  },
  flexCounter: {
    display: "flex",
    flexDirection: "row" as "row",
  },
  counterDividerMobile: {
    background: "white",
    width: "1px",
    opacity: "40%",
    transform: "skew(-20deg)",
    marginLeft: "1.25em",
    marginRight: "1.25em",
  },
  counterDivider: {
    background: "white",
    width: "1px",
    opacity: "40%",
    transform: "skew(-20deg)",
    marginLeft: "3.25em",
    marginRight: "3.25em",
  },
  calendarFlex: {
    display: "flex",
    flexDirection: "row" as "row",
    gap: "2%",
    justifyContent: "flex-end",
    width: "93%",
    //paddingTop: "1em"
  },
  mobileCalendar:
  {
    display: "flex",
    flexDirection: "row" as "row",
    gap: "2%",
    width: "93%",
    paddingTop: "1em"

  }
};
export default style;
