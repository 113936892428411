import {
  backgroundDark,
  brownLetters,
  whiteLetters,
} from "../constants/colors";
import BackgroundPNG from "../assets/images/BackgroundPNG.png";
import huuHeader from "../assets/images/huuHeader.png";
import mobileHuuHeader from "../assets/images/mobileHuuHeader.png";

const style = {
  parentDiv: {
    width: "100vw",
    height: "100vh",
    overflowX: "hidden" as "hidden",
    overflowY: "auto" as "auto",
    backgroundColor: backgroundDark,
    display: "flex",
    flexDirection: "column" as "column",
  },
  pictureBackground: {
    minHeight: "110vh",
    minWidth: "100vw",
    backgroundImage: "linear-gradient(to bottom, rgba(37,18,6,0) 20%,rgba(37,16,6,1)), url(" + huuHeader + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  calendarDivDestkop: {
    backgroundColor: brownLetters,
  },
  countdownDiv: {
    display: "flex",
    flexDirection: "row" as "row",
    width: "60%",
    margin: "auto",
  
  },
  textDiv: {
    width: "60%",
    margin: "auto",
    alignItems: "center",
  },
  hrStyle: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
    width: "100%",
    marginBottom:"-2%"
  },
  footerDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    width: "90%",
    margin: "auto",
  },
  footerTextDiv: {
    display: "flex",
    flexDirection: "row" as "row",
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5%",
  },
  footerText1: {
    color: brownLetters,
  },
  footerText2: {
    color: whiteLetters,
  },
  iconSize: {
    height: "20px",
    width: "20px",
  },
  socialMediaDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row" as "row",
    gap: "2%",
  },
  mobilePictureBackground: {
    backgroundImage: "linear-gradient(to bottom, rgba(37,18,6,0) 20%,rgba(37,16,6,1)), url(" + mobileHuuHeader + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minWidth: "100%",
    minHeight: "100%",
  },
  countdownDivMobile: {
    width: "95%",
    marginTop: "25%",
  },
  mobileTextDiv: {
    width: "95%",
    padding:"4%"
  },

};
export default style;
