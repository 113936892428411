import React from "react";
import {
  ArtistandCollaborators,
  ArtistandCollaboratorsText,
  ArtisticPartners,
  ArtisticPartnersText,
  OrganisationalPartnersNGOs,
  OrganisationalPartnersNGOsText,
  ProjectDescription,
  WhatisHuu,
} from "../../../constants/text";
import style from "./Title_Style";

const Title = () => {
  return (
    <div style={style.parent}>
      <WhatisHuu/>
      <div style={style.marginTop}>
        {" "}
        <ProjectDescription />
      </div>
      <ArtistandCollaborators />
      <div style={style.marginTop}>
        <ArtistandCollaboratorsText />
      </div>
      <OrganisationalPartnersNGOs />
      <div style={style.marginTop}>
        <OrganisationalPartnersNGOsText />
      </div>
      <ArtisticPartners/>
      <div style={style.marginTop}>
        <ArtisticPartnersText/>
      </div>
    </div>
  );
};
export default Title;
