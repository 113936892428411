
const style= {

    parent:{
        display:"flex",
        flexDirection:"column" as "column",
    },
    marginTop:{
        marginTop:"-5%"
    }
};
export default style;