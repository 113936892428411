import React, { createContext, useEffect } from "react";
import "./Landing.css";
import MainPage from "./pages/MainPage";
import analytics from "./analytics/initializeAnalytics";
import useWindowWidth from "./helpers/useWindowWidth";
import { logEvent } from "firebase/analytics";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import PureWeb from "./pages/components/PureWeb/PureWeb";
import Pure from './pureweb/Project';

export const WindowWidthContext = createContext(0);
function App() {
  const windowWidth = useWindowWidth();
  const urlToPureweb="https://preview.pureweb.io/0646f188-dd77-4d7d-996d-71924e15d059/61ae3484-7659-4e16-adf2-61d4a4da6876";
  useEffect(() => {
    logEvent(analytics, "App_Loaded");
  }, []);
  return (
    <HashRouter>
      <WindowWidthContext.Provider value={windowWidth}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/pureweb" element={<PureWeb url={urlToPureweb} />} />
          <Route path="/project" element={<Pure />} />
        </Routes>
      </WindowWidthContext.Provider>
    </HashRouter>
  );
}

export default App;
