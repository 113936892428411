import React, { useState, useContext, useEffect } from "react";
import {
  countdownDesktop,
  countdownMobile,
  countdownSmallMobile,
  intervalDesktop,
  intervalMobile,
  intervalSmallMobile,
} from "../../../constants/fonts";
import {
  MainSubtitle,
  MainTitle,
  ReleaseDate,
  ReleasedText,
} from "../../../constants/text";
import { WindowWidthContext } from "../../../Landing";
import style from "./Countdown_Style";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import calendarIcon from "../../../assets/images/calendarIcon.png";
import "../../../helpers/calendarStyle.css";

const calculateTimeLeft = () => {
  let difference = +new Date(`09/01/2022`) - +new Date();

  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60) + 1,
      seconds: Math.floor((difference / 1000) % 60) + 1,
    };
  }
  return timeLeft;
};
const event = {
  description: "Official release of the huu: project",
  duration: "3",
  endDatetime: "20220902",
  location: "huu.world",
  startDatetime: "20220901",
  timezone: "CET",
  title: "huu: release",
};
const AddToCalendarDropdown = AddToCalendarHOC("Text", "Dropdown");

const Countdown = (props) => {
  const windowWidth = useContext(WindowWidthContext);
  let isDesktop = windowWidth > 1023 ? true : false;
  let isSmallMobile = windowWidth < 420 ? true : false;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const timerComponents: object[] = [];
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      props.setTimer(timerComponents.length);
    }, 1000);
    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        <span style={isDesktop ? countdownDesktop : isSmallMobile ? countdownSmallMobile : countdownMobile}>
          {timeLeft[interval]}
        </span>
        <br></br>
        <span style={isDesktop ? intervalDesktop : isSmallMobile ? intervalSmallMobile : intervalMobile}>
          {interval}
        </span>
      </span>
    );
  });

  return (
    <div style={isDesktop? style.parentDiv:style.parentDivMobile}>
      <MainTitle />
      <div >
        <MainSubtitle />
      </div>
      <ReleaseDate />
      <br></br>
      {timerComponents.length ?<div>
      <div style={style.flexCounter}>
      {timerComponents.length && timerComponents[0]}
        <div
          style={isDesktop ? style.counterDivider : style.counterDividerMobile}
        ></div>
        {timerComponents.length && timerComponents[1]}
        <div
          style={isDesktop ? style.counterDivider : style.counterDividerMobile}
        ></div>
        {timerComponents.length && timerComponents[2]}
        <div
          style={isDesktop ? style.counterDivider : style.counterDividerMobile}
        ></div>
        {timerComponents.length && timerComponents[3] }
        </div>
      <div style={isDesktop?style.calendarFlex:style.mobileCalendar}>
        <div >
          <img src={calendarIcon} alt="calendar" />
        </div>
        <AddToCalendarDropdown
          className={isDesktop ? "containerStyle" : "containerStyleMobile"}
          buttonText={"Save the date"}
          items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
          linkProps={{
            className: "linkStyles",
          }}
          event={event}
          />
      </div></div>:<ReleasedText/>}
    </div>
  );
};
export default Countdown;
