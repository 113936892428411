import { brownLetters, orangeLetters, whiteLetters } from "./colors";

export const fontFamily = "Montserrat";
export const mainTitleDesktop = {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "8.125rem",
  color: whiteLetters,
};
export const textDescriptionDesktop= {
    marginTop: "3.125rem",
    fontFamily: fontFamily,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "1rem",
    color: whiteLetters,
};
export const subtitlesDesktop= {
    fontFamily: fontFamily,
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: "1.25rem",
    color: orangeLetters,
};
export const countdownDesktop= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "6.25rem",
  color: whiteLetters
};
export const intervalDesktop= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "1.875rem",
  color: brownLetters,
  // textTransform:"uppercase"
};
export const mainTitleMobile = {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "4.375rem",
  color: whiteLetters,
};

export const countdownMobile= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "1.725em",
  color: whiteLetters
};
export const countdownSmallMobile= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "1.7em",
  color: whiteLetters
};
export const intervalMobile= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "0.938em",
  color: brownLetters,
  // textTransform:"uppercase"
};
export const intervalSmallMobile= {
  fontFamily: fontFamily,
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: ".6em",
  color: brownLetters,
  // textTransform:"uppercase"
};