import React, { useContext } from "react";
import { brownLetters, whiteLetters } from "./colors";
import {
  mainTitleDesktop,
  textDescriptionDesktop,
  subtitlesDesktop,
  mainTitleMobile,
} from "./fonts";
import { WindowWidthContext } from "../Landing";

export const MainTitle = () => {
  const windowWidth = useContext(WindowWidthContext);
  let isDesktop = windowWidth > 1023 ? true : false;
  return (
    <p style={isDesktop ? { ...mainTitleDesktop } : { ...mainTitleMobile }}>
      huu:
    </p>
  );
};

export const ReleasedText = () => {
  return (
    <p style={{ ...subtitlesDesktop, ...{ color: whiteLetters } }}>
      Fully released!
    </p>
  );
};

export const MainSubtitle = () => {
  return (
    <p style={{ ...subtitlesDesktop, ...{ color: whiteLetters } }}>
      Digital Mnemonic Landscape / Web, VR{" "}
    </p>
  );
};

export const ReleaseDate = () => {
  return (
    <p style={{ ...subtitlesDesktop, ...{ color: whiteLetters } }}>September 1st </p>
  );
};

export const ProjectDescription = () => {
  return (
    <div>
      <p style={textDescriptionDesktop}>
        Huu:’ is a virtual mnemonic landscape based on personal histories of 22
        survivors of sexual violence in the war in Bosnia and Herzegovina in
        1992-1995 and the genocide against the Tutsi in Rwanda in 1994. The
        voices of these women and men inhabit an archipelago of floating
        islands, where each ecosystem reveals particular memories of a certain
        participant, in their journey from trauma to healing. We travel through
        these islands as wind, creating subtle gestures over waters and plant
        species – unveiling the documentary audio-recordings. As an intersection
        between ecological and personal memories, this project creates a safe
        space for survivors to tell their stories, giving them a chance to speak
        up about experiences which oftentimes remain taboo in their local
        societies. Huu: makes it possible for participants from geographically
        distant war and genocide-torn countries to unite in a virtual space and
        co-create a healing archipelago.
      </p>
    </div>
  );
};
export const WhatisHuu = () => {
  return (
    <div>
      <p style={subtitlesDesktop}>What is Huu:?</p>
    </div>
  );
};
export const ArtistandCollaborators = () => {
  return (
    <div>
      <p style={subtitlesDesktop}>Artist and Collaborators</p>
    </div>
  );
};

export const ArtistandCollaboratorsText = () => {
  return (
    <div>
      <p style={textDescriptionDesktop}>
        Artist: Smirna Kulenovic<br></br>Design Assistant: Christina Zhang
        <br></br> Audio Design: Vahid Qaderi<br></br> Technical Support: Nada
        <br></br>
      </p>
    </div>
  );
};

export const OrganisationalPartnersNGOs = () => {
  return (
    <div>
      <p style={subtitlesDesktop}>Organisational Partners:</p>
    </div>
  );
};
export const OrganisationalPartnersNGOsText = () => {
  return (
    <div>
      <p style={textDescriptionDesktop}>
        Justice for Women (Netherlands)<br></br>
        Impact: Center against Human Trafficking and Sexual Violence in Conflict
        (Netherlands) <br></br>
        Medica Zenica (Bosnia and Herzegovina) <br></br>
        Solace Ministries (Rwanda)<br></br>
        KUMA International<br></br>
        PureWeb<br></br>
      </p>
    </div>
  );
};

export const ArtisticPartners = () => {
  return (
    <div>
      <p style={subtitlesDesktop}>Artistic / Educational Partners</p>
    </div>
  );
};
export const ArtisticPartnersText = () => {
  return (
      <div>
        <p style={textDescriptionDesktop}>
        Yale University ( Yale College, Yale School of Architecture, and Yale
  CCAM)<br></br>
  ARS Electronica Center and Festival and Ars Electronica Deep Space 8K{" "}
  <br></br>
  University of Arts and Design Linz, Austria (Interface Cultures
  Department)<br></br>
        </p>
      </div>
  );
};
export const FooterText = () => {
  return (
    <div>
      <p style={textDescriptionDesktop}>Copyright ©2022 huu:</p>
    </div>
  );
};
