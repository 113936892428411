
// dark background color
export const backgroundDark="#251206";

//text color
export const whiteLetters="#FFFFFF";

export const brownLetters="#ffffff66";

export const calendarLetters="#FF8A00";

export const orangeLetters="#ff9a00";
